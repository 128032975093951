import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import * as _ from 'lodash';

import { testCheckApi } from "../_api/testCheck";

export const fetchTestCheck = createAsyncThunk(
  "testCheck/fetch",
  async (testParams, thunkAPI) => {
    try {
      console.log('testParams: ', testParams);
      const res = await testCheckApi(testParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  pick: [],
  answerOk: true,
  status: null,
  checking: false,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "testCheck",
  initialState,
  reducers: {
    setPick: (state, { payload }) => {
      state.pick = [...state.pick, payload];
      return state;
    },
    setOrderingPick: (state, { payload }) => {
      const picked = current(state.pick);
      const payloadType = _.keys(payload);

      if (picked.length === 0) {
        let prePayload = {node: [], combo: []};
        if (payloadType[0] === 'node') {
          prePayload.node.push(...payload['node']);
          state.pick = [prePayload];
          return state;
        }
  
        if (payloadType[0] === 'combo') {
          prePayload.combo.push(...payload['combo']);
          state.pick = [prePayload];
          return state;
        }
      } else {
        if (payloadType[0] === 'node') {
          // console.log('picked node: ', picked[0].node);
          // console.log('payload node: ', payload['node']);
          let nodeIndex = _.indexOf(picked[0].node, ...payload['node']);
          console.log('node index: ', nodeIndex);
          if (nodeIndex >= 0) {
            let combo = [...picked[0].combo];
            let node = [...picked[0].node];
            _.pullAt(node, [nodeIndex]);
            _.pullAt(combo, [nodeIndex]);
            console.log('pulled node: ', node);
            console.log('pulled combo: ', combo);
            state.pick = [{node: node, combo: combo}];
            return state;
          };
          state.pick = [{node: [...picked[0].node, ...payload['node']], combo: [...picked[0].combo]}];
          return state;
        }
  
        if (payloadType[0] === 'combo') {
          state.pick = [{node: [...picked[0].node], combo: [...picked[0].combo, ...payload['combo']]}];
          return state;
        }
      }
    },
    removePick: (state, { payload }) => {
      const removePick = state.pick.filter(pick => payload !== pick);
      state.pick = removePick;
      console.log('removed', removePick);
      return state;
    },
    removeConnectionPick: (state, { payload }) => {
      const picked = current(state.pick);
      const result = picked.filter(pick => 
        !pick.every( el => payload.includes(el))
      );
      state.pick = result;
      console.log('removedEdge');
      return state;
    },
    setAnswerOk: (state, { payload }) => {
      state.answerOk = payload;
      return state;
    },
    setChecking: (state, { payload }) => {
      state.checking = payload;
      return state;
    },
    resetPick: (state) => {
      state.pick = [];
      return state;
    }
  },
  extraReducers: {
    [fetchTestCheck.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [fetchTestCheck.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.status = [...payload];
      return state;
    },
    [fetchTestCheck.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { 
  setPick,
  setOrderingPick, 
  setAnswerOk, 
  setChecking, 
  resetPick, 
  removePick, 
  removeConnectionPick 
} = slice.actions;

// Selectors
export const pickSelector = (state) => state.testCheck.pick;
export const answerOkSelector = (state) => state.testCheck.answerOk;
export const checkingSelector = (state) => state.testCheck.checking;
export const statusSelector = (state) => state.testCheck.status;
export const testCheckLoadingSelector = (state) => state.testCheck.loading;

