import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { classGridApi } from "../_api/classGrid";

export const fetchClassGrid = createAsyncThunk(
  "classGrid/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await classGridApi();
      console.log('classGrid....', res.data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  classGrid: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "classGrid",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchClassGrid.pending]: (state) => {
      state.classGrid = null;
      state.loading = true;
      return state;
    },
    [fetchClassGrid.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.classGrid = payload;
      return state;
    },
    [fetchClassGrid.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { logOut } = slice.actions;

// Selectors
export const classGridSelector = (state) => state.classGrid.classGrid;
export const classGridLoadingSelector = (state) => state.classGrid.loading;
