import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import { isAuthenticatedSelector } from "../../../_slices/user";
import { MainPageWrapper, MainPageOutter, MainPageInner } from "./atoms";
import { MainContainer } from "../../components/atoms";
// import { DashBoardPage } from "../../pages/dashboard";
// import { StudentsPage } from "../../pages/students";
// import { SchoolSchedulePage } from "../../pages/school-schedule";
// import { SubjectsPage } from "../../pages/subjects";
// import { SubjectPage } from "../../pages/subject";
// import { TopicPage } from "../../pages/topic";
// import { NotFoundPage } from "../notFound";
import { Header } from "./organisms";

const DashBoardPage = lazy(() => import("../../pages/dashboard"));
const PassedTopicsPage = lazy(() => import("../../pages/passed-topics"));
// const SchoolSchedulePage = lazy(() => import("../../pages/school-schedule"));
const KindergartenSchedulePage = lazy(() => import("../../pages/kindergarten-schedule"));
const SubjectsPage = lazy(() => import("../../pages/subjects"));
const SubjectPage = lazy(() => import("../../pages/subject"));
const TopicPage = lazy(() => import("../../pages/topic"));
const ChallengePage = lazy(() => import("../../pages/challenge"));
const NotFoundPage = lazy(() => import("../../screens/notFound"));

export const Main = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const token = Cookies.get('access-token');
  return (
    <>
      {(isAuthenticated && token) ? (
        
          <MainPageWrapper>
            <Header />
            <MainPageOutter>
              <MainContainer>
                <MainPageInner>
                  <Suspense
                    fallback={
                      <Row justify='center' align='middle' style={{ height: "100vh" }}>
                        <Col>
                          <SyncOutlined
                            style={{ fontSize: "56px", color: "#06667c" }}
                            spin
                          />
                        </Col>
                      </Row>
                    }
                  >
                    <Switch>
                      <Route exact path='/'>
                        {/* <SubjectsPage /> */}
                        <DashBoardPage />
                      </Route>
                      <Route exact path='/subjects'>
                        <SubjectsPage />
                      </Route>
                      <Route exact path='/subjects/:subjectId'>
                        <SubjectPage />
                      </Route>
                      <Route exact path='/subjects/:subject/:topicId'>
                        <TopicPage />
                      </Route>
                      <Route exact path='/challenge/:dayId'>
                        <ChallengePage />
                      </Route>
                      {/* <Route exact path='/school-schedule'>
                        <SchoolSchedulePage />
                      </Route> */}
                      <Route exact path='/kindergarten-schedule'>
                        <KindergartenSchedulePage />
                      </Route>
                      <Route exact path='/passed-topics'>
                        <PassedTopicsPage />
                      </Route>
                      <Route path='*'>
                        <NotFoundPage />
                      </Route>
                    </Switch>
                  </Suspense>
                </MainPageInner>
              </MainContainer>
            </MainPageOutter>
          </MainPageWrapper>
      ) : (
        <Redirect to='/sign-in' />
      )}
    </>
  );
};
