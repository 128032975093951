import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { weeksApi } from "../_api/weeks";

export const fetchWeeks = createAsyncThunk(
  "weeks/fetch",
  async (month_id, thunkAPI) => {
    try {
        console.log('weeks....')
      const res = await weeksApi(month_id);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  weeks: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "weeks",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWeeks.pending]: (state) => {
      state.weeks = null;
      state.loading = true;
      return state;
    },
    [fetchWeeks.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.weeks = [...payload];
      return state;
    },
    [fetchWeeks.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { logOut } = slice.actions;

// Selectors
export const weeksSelector = (state) => state.weeks.weeks;
export const weeksLoadingSelector = (state) => state.weeks.loading;
