import styled from "styled-components";

export const HeaderGroupNum = styled.div`
  font-size: 20px;
  font-family: Roboto;
  color: #4a4a4a;
  text-align: right;
`;

export const HeaderDate = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-family: Roboto;
  color: #4a4a4a;
  text-align: right;
`;
