import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Modal } from "antd";
import { PoweroffOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {reactLocalStorage} from 'reactjs-localstorage';

import {
  LogoutBtn,
  HeaderGroupNum,
  HeaderDate,
  MainHeaderLogoutInfoWrapper,
} from "../atoms";
import { logOut, userSelector } from "../../../../_slices/user";

export const HeaderLogoutInfo = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const user = useSelector(userSelector);

  const handleClick = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(logOut());
    reactLocalStorage.clear();
    history.push("/sign-in");
  };

  const handleCancel = () => setIsModalVisible(false);

  return (
    <MainHeaderLogoutInfoWrapper>
      <Row justify='end' align='middle'>
        <Col>
          <HeaderDate>
            Сегодня: {user ? user[1].date : '-'}
          </HeaderDate>
          <HeaderGroupNum>
            Группа: <span>{user[0].group}</span>
          </HeaderGroupNum>
        </Col>
        <Col offset={1}>
          <LogoutBtn
            type='primary'
            shape='circle'
            size='large'
            danger
            icon={
              <PoweroffOutlined
                style={{ fontSize: "18px", color: "#ffffff" }}
              />
            }
            onClick={handleClick}
          />
          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            centered
            icon={<ExclamationCircleOutlined />}
            okText='ДА'
            cancelText='НЕТ'
            focusTriggerAfterClose={false}
            okButtonProps={{ size: "large" }}
            cancelButtonProps={{ size: "large" }}
            bodyStyle={{
              fontSize: "26px",
              fontWeight: "bold",
              fontFamily: "Roboto",
            }}
          >
            <p>Вы уверены что хотите выйти?</p>
          </Modal>
        </Col>
      </Row>
    </MainHeaderLogoutInfoWrapper>
  );
};
