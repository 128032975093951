import React from "react";
import { SignInWrap } from "./atoms";
import { FormBlock } from "./organisms";

export const SignIn = () => {
  return (
    <SignInWrap>
      <FormBlock />
    </SignInWrap>
  );
};
