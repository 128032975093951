import { NavLink, useLocation, matchPath } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { NavMenu, NavMenuItem } from "../atoms";

export const HeaderNav = () => {
  const [currentLink, setCurrentLink] = useState("main-page");
  let { pathname } = useLocation();

  const links = [
    {
      path: "/",
      title: "Главная",
    },
    {
      path: "/subjects",
      title: "Предметы",
    },
    {
      path: "/kindergarten-schedule",
      title: "Сетка занятий",
    },
    // {
    //   path: "/students",
    //   title: "Успеваемость",
    // },
  ];

  const getCurrentActiveLink = () => {
    let currentActiveLink = links.filter((link) =>
      matchPath(pathname, {
        path: link.path,
        exact: false,
        strict: false,
      })
    );

    if (currentActiveLink.length > 1) {
      setCurrentLink(currentActiveLink[1].path);
    } else {
      setCurrentLink(currentActiveLink[0].path);
    }
  };

  useEffect(() => {
    getCurrentActiveLink();
  });

  return (
    <NavMenu selectedKeys={[currentLink]} mode='horizontal'>
      {links.map((link) => (
        <NavMenuItem key={link.path}>
          <NavLink to={link.path}>{link.title}</NavLink>
        </NavMenuItem>
      ))}
    </NavMenu>
  );
};
