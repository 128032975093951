import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { subjectsApi } from "../_api/subjects";

export const fetchSubjects = createAsyncThunk(
  "subjects/fetch",
  async (ageClassId, thunkAPI) => {
    try {
      const res = await subjectsApi(ageClassId);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  subjects: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "subjects",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubjects.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [fetchSubjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.subjects = [...payload];
      return state;
    },
    [fetchSubjects.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { logOut } = slice.actions;

// Selectors
export const subjectsSelector = (state) => state.subjects.subjects;
export const loadingSelector = (state) => state.subjects.loading;
