import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { SignIn } from "../auth";
import { Main } from "../main";
import NotFoundPage from "../notFound";

export const Root = () => {

  return (
    <Router>
      <Switch>
        <Route exact path='/sign-in'>
          <SignIn />
        </Route>
        <Route path='/'>
          <Main />
        </Route>
        <Route path='*'>
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
};
