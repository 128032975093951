import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { topicsApi } from "../_api/topics";

export const fetchTopics = createAsyncThunk(
  "topics/fetch",
  async (weekId, thunkAPI) => {
    try {
      console.log('topics....');
      const res = await topicsApi(weekId);
      console.log(res.data);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  topics: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "topics",
  initialState,
  reducers: {
    clearTopics: (state) => {
      state = initialState;
      return state;
    },
  },
  extraReducers: {
    [fetchTopics.pending]: (state) => {
      state.topics = null;
      state.loading = true;
      return state;
    },
    [fetchTopics.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.topics = [...payload];
      return state;
    },
    [fetchTopics.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { clearTopics } = slice.actions;

// Selectors
export const topicsSelector = (state) => state.topics.topics;
export const topicsLoadingSelector = (state) => state.topics.loading;
