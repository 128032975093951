import { Result, Button } from 'antd';
import { NavLink } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Извините, но такой страницы не сушествует!"
      extra={
        <NavLink to="/">
          <Button type="primary">Вернуться на главную</Button>
        </NavLink>
      }
    />
  )
};

export default NotFoundPage;
