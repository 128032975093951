import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "antd";

import {
  FormWrap,
  FormInput,
  FormInputPassword,
  FormButton,
  FormLogo,
} from "../atoms";
import { logIn, loadingSelector } from "../../../../_slices/user";

export const FormBlock = () => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  let history = useHistory();

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const tailLayout = {
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = async (values) => {
    await dispatch(logIn(values));
    history.push("/");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <FormWrap>
      <FormLogo />
      <Form
        {...layout}
        name='basic'
        layout='vertical'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label='Введите ID группы:'
          name='id'
          rules={[
            { required: true, message: "Пожалуйста введите ID своей группы!" },
          ]}
        >
          <FormInput placeholder='12345678' disabled={loading} />
        </Form.Item>

        <Form.Item
          label='Пароль:'
          name='password'
          rules={[{ required: true, message: "Пожалуйста введите пароль!" }]}
        >
          <FormInputPassword placeholder='Введите пароль' disabled={loading} />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <FormButton type='primary' htmlType='submit' loading={loading}>
            Войти
          </FormButton>
        </Form.Item>
      </Form>
    </FormWrap>
  );
};
