import { Row, Col } from "antd";

import { MainContainer } from "../../../components/atoms";
import {
  MainPageHeader,
  MainPageHeaderInner,
  HeaderLogo,
  MainHeaderNavWrapper,
} from "../atoms";
import { HeaderLogoutInfo, HeaderNav } from "../molecules";

export const Header = () => {
  return (
    <MainPageHeader>
      <MainContainer>
        <MainPageHeaderInner>
          <Row justify='space-between' align='middle'>
            <Col span={4}>
              <HeaderLogo />
            </Col>
            <Col span={20}>
              <MainHeaderNavWrapper>
                <Row align='middle' justify='end'>
                  <Col>
                    <HeaderNav />
                  </Col>
                  <Col span={6}>
                    <HeaderLogoutInfo />
                  </Col>
                </Row>
              </MainHeaderNavWrapper>
            </Col>
          </Row>
        </MainPageHeaderInner>
      </MainContainer>
    </MainPageHeader>
  );
};
