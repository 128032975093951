import { useEffect } from "react";
import {reactLocalStorage} from 'reactjs-localstorage';
import { GlobalStyle } from "../theme/global-styles";
import { Root } from "./screens/root";
require("dotenv").config();



function App() {
  
  useEffect(() => {
    window.addEventListener("onclose", () => reactLocalStorage.clear());

    return () => {
      window.removeEventListener("onclose", () => reactLocalStorage.clear());
    }
  });

  return (
    <div className='page-wrapper'>
      <GlobalStyle />
      <Root />
    </div>
  );
}

export default App;
