import axios from "axios";
import Cookies from "js-cookie";
import { notification } from 'antd';


const openNotificationWithIcon = (type, mesg, desc) => {
  notification[type]({
      message: mesg,
      description: desc,
  });
};

const httpClient = axios.create({
    baseURL: "https://api.mdo.rrpo.uz",
    // withCredentials: true,
});

httpClient.interceptors.response.use(
  (response) => {
    // let status = response.status;
    // if (status === 200) {
    //   const content =
    //     <div>
    //         <br />
    //         Статус: {status}<br />
    //         Группа: {response.data.group[0].group}<br />
    //         Сообщение: `Добро пожаловать группа #{response.data.group[0].group}`
    //     </div>;
    //   openNotificationWithIcon(
    //       'success',
    //       'Вы авторизованы',
    //       content);
    // }
    return response;
  },
  (error) => {
    // let config = error.config;
    let status = (error.response && error.response.status) || 0;
    // const originalRequest = config;

    // console.log('ошибка данных: ', error.config);

    if (status === 401) {
      if (window.location.pathname === '/sign-in') {
        const content =
          <div>
              {error.response.data.title}<br />
              Статус: {status}<br />
              Детали: {error.message}<br />
              Сообщение: 'Не авторизованы'
          </div>;
        openNotificationWithIcon(
            'error',
            'Неправильный логин/пароль!',
            content);
      } else {
          window.location.href = '/sign-in';
      }
    } else if(status === 403){

    } else{

    }
    return Promise.reject(error);
  }
);

httpClient.interceptors.request.use((config) => {
  let token = Cookies.get("access-token");
  if (token) {
    config.headers = Object.assign(config.headers, {
      Authorization: "Bearer " + token,
    });
    return config;
  } else {
    return config;
  }
});

export const httpGet = (params) =>
  httpClient({
    method: "get",
    ...params,
  });

export const httpPost = (params) =>
  httpClient({
    method: "post",
    ...params,
  });

export const httpPut = (params) =>
  httpClient({
    method: "put",
    ...params,
  });

export const httpPatch = (params) =>
  httpClient({
    method: "patch",
    ...params,
  });

export const httpDelete = (params) =>
  httpClient({
    method: "delete",
    ...params,
  });
