import styled from "styled-components/macro";
import SignInBg from "../../../../assets/images/back-login.jpg";

export const SignInWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: url(${SignInBg}) no-repeat center center;
  background-size: cover;
`;

export const FormWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100%;
  max-width: 650px;
  padding: 35px 75px 11px;

  border-radius: 10px;
  transform: translate(-50%, -50%);

  background-color: rgba(1, 245, 246, 0.3);

  label {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
  }
`;
