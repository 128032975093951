import { Menu } from "antd";
import styled from "styled-components/macro";

export const NavMenu = styled(Menu)`
  height: 100%;
  background-color: transparent;

  .ant-menu-item a {
    color: #4a4a4a;
  }

  .ant-menu-item a:hover {
    color: #f1b73c;
  }

  :not(.ant-menu-dark) > .ant-menu-item:hover,
  :not(.ant-menu-dark) > .ant-menu-submenu:hover,
  :not(.ant-menu-dark) > .ant-menu-item-active,
  :not(.ant-menu-dark) > .ant-menu-submenu-active,
  :not(.ant-menu-dark) > .ant-menu-item-open,
  :not(.ant-menu-dark) > .ant-menu-submenu-open,
  :not(.ant-menu-dark) > .ant-menu-item-selected,
  :not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #f1b73c;
    border-bottom: 2px solid #f1b73c;
  }
  .ant-menu-item-selected a {
    color: #f1b73c;
  }
`;

export const NavMenuItem = styled(Menu.Item)`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
`;
