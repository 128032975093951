import styled from "styled-components/macro";
import MainPageBg from "../../../../assets/images/back-all.jpg";

export const MainPageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  background: url(${MainPageBg}) no-repeat center center;
  background-size: cover;
`;

export const MainPageOutter = styled.main`
  position: absolute;
  top: calc(50% + 55px);
  left: 50%;
  width: 100%;

  transform: translate(-50%, -50%);
`;

export const MainPageInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 820px;
  padding: 30px 50px;
  overflow: hidden;

  border-radius: 10px;

  background-color: #d8fbfd;
`;

export const MainPageHeader = styled.header`
  width: 100%;
  margin: 0 0 42px 0;

  background-color: #d8fbfd;
`;

export const MainPageHeaderInner = styled.div`
  padding: 21px 0;

  @media (min-width: 980px) and (max-width: 1560px) {
    padding: 21px 16px;
  }
`;

export const MainHeaderNavWrapper = styled.div``;

export const MainHeaderLogoutInfoWrapper = styled.div``;
