import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { passedTopicsApi } from "../_api/passedTopics";

export const fetchPassedTopics = createAsyncThunk(
  "passedTopics/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await passedTopicsApi();
      console.log(res.data);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  passedTopics: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "passedTopics",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPassedTopics.pending]: (state) => {
      state.passedTopics = null;
      state.loading = true;
      return state;
    },
    [fetchPassedTopics.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.passedTopics = [...payload];
      return state;
    },
    [fetchPassedTopics.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { logOut } = slice.actions;

// Selectors
export const passedTopicsSelector = (state) => state.passedTopics.passedTopics;
export const passedTopicsLoadingSelector = (state) => state.passedTopics.loading;
