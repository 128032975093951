export {
  MainPageWrapper,
  MainPageOutter,
  MainPageInner,
  MainPageHeader,
  MainPageHeaderInner,
  MainHeaderNavWrapper,
  MainHeaderLogoutInfoWrapper,
} from "./wrappers";

export { LogoutBtn } from "./buttons";

export { HeaderGroupNum, HeaderDate } from "./header-group-num";

export { HeaderLogo } from "./logos";

export { NavMenu, NavMenuItem } from "./nav";
