import styled from "styled-components/macro";
import { Link } from 'react-router-dom';
import LogoImg from "../../../../assets/images/sign-in-logo.png";

const LogoWrap = styled(Link)`
  width: 100%;

  img {
    width: 70%;
  }
`;

export const HeaderLogo = () => {
  return (
    <LogoWrap to="/">
      <img src={LogoImg} alt='Logo' />
    </LogoWrap>
  );
};
