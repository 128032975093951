import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { monthsApi } from "../_api/months";

export const fetchMonths = createAsyncThunk(
  "months/fetch",
  async (subject_id, thunkAPI) => {
    try {
        console.log('months....')
      const res = await monthsApi(subject_id);
      console.log(res.data);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  months: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "months",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMonths.pending]: (state) => {
      state.months = null;
      state.loading = true;
      return state;
    },
    [fetchMonths.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.months = [...payload];
      return state;
    },
    [fetchMonths.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { logOut } = slice.actions;

// Selectors
export const monthsSelector = (state) => state.months.months;
export const monthsLoadingSelector = (state) => state.months.loading;
