import styled from "styled-components/macro";
import FormLogoImg from "../../../../assets/images/sign-in-logo.png";

const FormLogoWrap = styled.div`
  width: 60%;
  margin: 0 auto;

  img {
    margin: 0 auto 45px;
  }
`;

export const FormLogo = () => {
  return (
    <FormLogoWrap>
      <img src={FormLogoImg} alt='Form Logo' />
    </FormLogoWrap>
  );
};
