import { combineReducers } from "redux";

import user from "../_slices/user";
import topics from "../_slices/topics";
import subjects from "../_slices/subjects";
import months from "../_slices/months";
import weeks from "../_slices/weeks";
import challenge from "../_slices/challenge";
import testCheck from '../_slices/testCheck';
import passedTopics from '../_slices/passedTopics';
import classGrid from '../_slices/classGrid';

const reducers = combineReducers({
  user,
  topics,
  subjects,
  months,
  weeks,
  challenge,
  testCheck,
  passedTopics,
  classGrid
});

export default reducers;
