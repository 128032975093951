import styled from "styled-components/macro";
import { Input } from "antd";

const inputCommon = `
  width: 100%;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 58px;
  padding: 0 36px;
  border-radius: 10px;
`;

export const FormInput = styled(Input)`
  ${inputCommon}
`;

export const FormInputPassword = styled(Input.Password)`
  ${inputCommon}

  input {
    font-size: 20px;
  }
`;
