import styled from "styled-components/macro";
import { Button } from "antd";

export const FormButton = styled(Button)`
  height: 64px;
  padding: 0 64px;
  outline: none;
  vertical-align: super;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 10px;
  border: none;
  background-color: rgba(106, 146, 11);

  :hover {
    background-color: rgba(106, 146, 11, 0.7);
  }

  span {
    font-size: 24px;
    font-weight: bold;
    line-height: 64px;
  }
`;
