import styled from "styled-components";

export const MainPageSectionHeading = styled.h4`
  font-family: Roboto;
  font-size: ${({ size }) => (size === "large" ? "26px" : "20px")};
  font-weight: bold;
  color: #232323;

  text-align: ${({ align }) => (align ? align : "left")};
`;
