import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { logInAPI } from "../_api/user";

export const logIn = createAsyncThunk(
  "user/logIn",
  async (formData, thunkAPI) => {
    try {
      console.log('Starting...');
      const res = await logInAPI(formData);
      if (res.status === 200) {
        await Cookies.set("access-token", res.data.access_token, {
          expires: 1,
        });
      }
      return [...res.data.group, {date: res.data.date}];
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  currentUser: null,
  loading: false,
  hasErrors: false,
  error: "",
  isAuthenticated: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOut: (state) => {
      Cookies.remove("access-token");
      state.isAuthenticated = false;
      state.currentUser = null;
      return state;
    },
  },
  extraReducers: {
    [logIn.pending]: (state) => {
      Cookies.remove("access-token");
      state.currentUser = null;
      state.loading = true;
      return state;
    },
    [logIn.fulfilled]: (state, { payload }) => {
      let token = Cookies.get("access-token");
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.currentUser = [...payload];
      if (token) {
        state.isAuthenticated = true;
      }
      return state;
    },
    [logIn.rejected]: (state, { error }) => {
      Cookies.remove("access-token");
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { logOut } = slice.actions;

// Selectors
export const userSelector = (state) => state.user.currentUser;
export const loadingSelector = (state) => state.user.loading;
export const isAuthenticatedSelector = (state) => state.user.isAuthenticated;
