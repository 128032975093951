import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { challengeApi } from "../_api/challenge";

export const fetchChallenge = createAsyncThunk(
  "challenge/fetch",
  async (dayId, thunkAPI) => {
    try {
      const res = await challengeApi(dayId);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  challenge: null,
  currentTest: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    setCurrentTest: (state, { payload }) => {
      state.currentTest = payload;
      return state;
    },
  },
  extraReducers: {
    [fetchChallenge.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [fetchChallenge.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      state.challenge = [...payload];
      return state;
    },
    [fetchChallenge.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setCurrentTest } = slice.actions;

// Selectors
export const challengeSelector = (state) => state.challenge.challenge;
export const currentTestSelector = (state) => state.challenge.currentTest;
export const challengeLoadingSelector = (state) => state.challenge.loading;
